import { mapBlockPaddingValue } from '@lib/slice-machine/blockPadding';
import { mapBlockWidthToBreakpoint } from '@lib/slice-machine/blockWidth';
import {
  BackgroundColor,
  BlockPadding,
  BlockWidth,
} from '@lib/slice-machine/types';
import {
  useTenantComponentStyles,
  UseTenantComponentStylesReturn,
} from '@lib/tenants/useTenantComponentStyles';
import { SxStyles } from '@lib/theme/sxTheme';
import { Box, BoxProps } from '@mui/material';
import { ContentSpacer } from '@templates/common/layout/ContentSpacer';

interface Props {
  backgroundColor?: BackgroundColor | null;
  paddingTop?: BlockPadding | null;
  paddingBottom?: BlockPadding | null;
  width?: BlockWidth | null;
  contentProps?: BoxProps;
}

export const SliceLayout: React.FC<React.PropsWithChildren<Props>> = ({
  backgroundColor,
  paddingTop = null,
  paddingBottom = null,
  width = null,
  contentProps = {},
  children,
}) => {
  const { sx: contentSx, ...restContentProps } = contentProps;
  const { spacing } = useTenantComponentStyles();
  const dynamicStyles = styles({
    paddingTop,
    paddingBottom,
    spacing,
  });

  return (
    <Box
      sx={{ ...dynamicStyles.getValue('root'), ...contentSx }}
      {...restContentProps}
      bgcolor={backgroundColor ?? undefined}
    >
      <ContentSpacer
        isPadded={false}
        contentProps={{ sx: dynamicStyles.getValue('content') }}
        {...(width && { maxWidth: mapBlockWidthToBreakpoint(width) })}
      >
        {children}
      </ContentSpacer>
    </Box>
  );
};

interface StyleProps {
  paddingTop: BlockPadding | null;
  paddingBottom: BlockPadding | null;
  spacing: UseTenantComponentStylesReturn['spacing'];
}

const styles = ({ paddingTop, paddingBottom, spacing }: StyleProps) => {
  return new SxStyles({
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    content: {
      pt: mapBlockPaddingValue(paddingTop, spacing),
      pb: mapBlockPaddingValue(paddingBottom, spacing),
    },
  });
};
